
html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#no-cursor {
    pointer-events: none;
}

@font-face {
  font-family: Outlander;
  src: url("../../public/outlander-signature.woff");
}
@font-face {
  font-family: Podkova;
  src: url("../../public/Podkova-VariableFont_wght.ttf");
}
@font-face {
  font-family: Quicksand;
  src: url("../../public/Quicksand-VariableFont_wght.ttf");
}
@font-face {
  font-family: OpenSans;
  src: url("../../public/OpenSans-VariableFont_wdth\,wght.ttf");
}
html,body{
  height:100%
}

body {
  font-family: 'Outlander';
  font-weight: 400;
  color: #FFD700;
  margin: 0;
}

body:has(.home) {
  background: linear-gradient(
    rgba(0, 0, 0, .1), 
    rgba(0, 0, 0, .1)), 
    url('../../public/nebula-smaller.jpeg');
    animation: float linear infinite 1500s;
}

body:has(.not_home) {
  background-color: #fffbf0;
  /* background-image: url('https://images.zola.com/c910052e-61b9-444d-ada5-fd4f2c4d5202?w=2500');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain; */
}
.not_home .background {
  position: absolute;
  min-height: calc(100vh - 57px);
  height: 100%;
  width: 100%;
  top: 57px;
  z-index: -10;
}
.not_home .background img {
  max-width: 60vw;
  max-height: 60vh;
  position: absolute;
}
.not_home .background .top-right {
  top: 0;
  right: 0;
}
.not_home .background .top-left {
  top: 0;
  left: 0;
}
.not_home .background .bottom-right {
  bottom: 0;
  right: 0;
}
.not_home .background .bottom-left {
  bottom: 0;
  left: 0;
}

.homebody {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homeButtons {
  min-width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.homeButtons {
  min-width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.tab-title {
  font-size: 4rem;
  font-weight: 700;
}

.readable-background {
  /* background: linear-gradient(
    rgba(214, 224, 102, 0.8),
    rgba(255, 215, 0, .6)); */
    font: 1.2rem Podkova;
    line-height: 1.5;
    color: darkslategray;
    margin: 2rem;
    padding: 2rem;
    width: 80vw;
    min-height: calc(80dvh - (180px + 64px));
    /* box-shadow: 
      rgba(214, 224, 102, 0.8) 0 0 2px 2px, 
      rgba(255, 215, 0, 0.5) 0 0 5px 5px; */
}

.readable-background a {
  color: darkslategray;
  text-decoration-color: rgb(243 106 60 / 40%);
  font-weight: 500;
}

.header {
  position: absolute;
  z-index: 15;
  width: 100%;
}

/* .header > :nth-child(2) {
  color: #FF577F;
} */

.MuiDrawer-paperAnchorLeft {
  background-color: #FFF8E3 !important;
}

.drawer-close {
  display: flex;
  justify-content: right;
}

.header-logo {
  font-weight: 400;
  text-decoration: none;
  color: gold;
}

.content-wrapper {
  z-index: 10;
  position: absolute;
  padding-top: 64px;
  width: 100%;
}

@media (min-width: 750px) {
  .header {
    height: 160px;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .header-logo {
    font-size: 3.5rem;
    padding-top: 1rem;
  }
  .content-wrapper {
    padding-top: 160px;
  }
}

@media (max-width: 750px) {
  .header {
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .header-logo {
    font-size: 1.5rem;
  }
  .content-wrapper {
    padding-top: 64px;
  }
  .not_home .background {
    min-height: 100vh;
    top: 0;
  }
}

.header .menu-icon {
  font-size: 2rem;
  color: gold;
}

.menu-icon svg {
  height: 2.5rem;
  width: 2.5rem;
}

.details {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 32px;
}

.details a, .details a:hover {
  margin-top: 1rem;
  font-weight: 700;
  border-width: 3px;
}

.logo-with-shadow {
  text-shadow: 
    2px 2px 0px #ffc106,
    4px 4px 5px #f38505,
    -1px -1px 1px #fcff4f;
}

.logo {
  width: 65%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1000px) {
  .logo b {
    font-size: 12vh;
    margin-right: 2rem;
  }
}

@media (max-width: 1000px) {
  .logo {
    flex-direction: column;
  }
  .logo b {
    font-size: 10vh;
  }
  .logo b:nth-child(2) {
    margin: -1rem 0 -2rem;
    font-size: 5vh;
  }
}

.schedule-date {
  font-size: 2rem;
  text-align: center;
  padding: 1rem 0 2rem;
}

.schedule-location {
  text-align: center;
}

.schedule-item {
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  height: 10rem;
  justify-content: space-evenly;
}

.schedule-item > .schedule-item-divider {
  width: 1px;
  height: 75%;
  border-left: 1px solid gold;
}

/* @media (max-width: 840px) { */
  .schedule-item > .schedule-item-divider {
   display: none;
  }
/* } */

.schedule-item-left {
  /* flex-direction: row; */
  flex-direction: column;
}

.schedule-item-right {
  /* flex-direction: row-reverse; */
  flex-direction: column;
}

.schedule-item > div {
  width: 20rem;
  text-align: center;
}

.schedule-label {
  font-size: 2rem;
  font-weight: 200;
  font-family: 'Quicksand';
  color: goldenrod;
}

.question {
  font-weight: 700;
  margin-top: 1rem;
}

.rsvpForm > div {
  padding: 0.5rem 0;
}

.radioGroup {
  display: flex;
  flex-direction: column;
}

.radioGroup.error {
  border: 1px solid red;
  border-radius: 5px;
}

.radioGroup input {
  margin-right: 0.5rem;
}

#rsvp-btn {
  background-color: rgba(253, 76, 0, 0.5);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

#rsvp-btn:hover {
  box-shadow: #f38505 0 3px 8px;
}

.input-label {
  display: block;
}

input[type=text], textarea {
  width: 100%;
  padding: 8px 10px;
  margin: 8px 0;
  border-radius: 5px;
  border: solid 1px darkslategray;
}

input[type=text].error, textarea.error {
  border: 1px solid red;
}

.loader {
  position: relative;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgb(255, 243, 150);
  width: 70px;
  height: 70px;
  left:50%;
  top:50%;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

#overlay{
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .5;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.star {
  position: absolute;
  top:50%;
  left:50%;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255,255,255,0.1),0 0 0 8px rgba(255,255,255,0.1),0 0 20px rgba(255,255,255,0.1);
  animation: fall 3s linear infinite;
  z-index: 100;
}

.star::before{
    content:'';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 1px;
    background: linear-gradient(90deg,#fff,transparent);
    animation: tail-fade 3s linear infinite;
}

@keyframes fall
{
    0%
    {
        transform: rotate(315deg) translateX(0);
        opacity: 1;
    }
    70%
    {
        opacity: 1;
    }
    100%
    {
        transform: rotate(315deg) translateX(-1000px);
        opacity: 0;
    }
}
@keyframes tail-fade {
  0%, 30% {
    width: 100px;
    opacity: 1;
  }
  70%, 80% {
    width: 0;
    opacity: 0.4;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}
.star:nth-child(1){
    top: 0;
    right: 0;
    left: initial;
    animation-delay: 0s;
    animation-duration: 1s;
    background: #96ffff;
    box-shadow: 0 0 0 4px rgba(150,255,255,0.1),0 0 0 8px rgba(150,255,255,0.1),0 0 20px rgba(150,255,255,0.1);
}
.star:nth-child(1)::before{
  animation-delay: 0s;
  animation-duration: 1s;
    background: #96ffff;
    background: linear-gradient(90deg, #96ffff,transparent);
}
.star:nth-child(2){
    top: 0;
    right: 80px;
    left: initial;
    animation-delay: 0.2s;
    animation-duration: 3s;
}
.star:nth-child(2)::before{
  animation-delay: 0.2s;
  animation-duration: 3s;
}
.star:nth-child(3){
    top: 80;
    right: 0px;
    left: initial;
    animation-delay: 0.4s;
    animation-duration: 2s;
    box-shadow: 0 0 0 4px rgba(255, 150, 202, 0.1),0 0 0 8px rgba(255, 150, 202,0.1),0 0 20px rgba(255, 150, 202, 0.1);
}
.star:nth-child(3)::before{
  animation-delay: 0.4s;
  animation-duration: 2s;
  background: rgb(225, 150, 202);
  background: linear-gradient(90deg, rgb(225, 150, 202),transparent);
}
.star:nth-child(4){
    top: 0;
    right: 180px;
    left: initial;
    animation-delay: 0.6s;
    animation-duration: 1.5s;
}
.star:nth-child(4)::before{
  animation-delay: 0.6s;
  animation-duration: 1.5s;
}
.star:nth-child(5){
    top: 0;
    right: 400px;
    left: initial;
    animation-delay: 0.8s;
    animation-duration: 2.5s;
}
.star:nth-child(5)::before{
  animation-delay: 0.8s;
  animation-duration: 2.5s;
}
.star:nth-child(6){
    top: 0;
    right: 600px;
    left: initial;
    animation-delay: 1s;
    animation-duration: 3s;
}
.star:nth-child(6)::before{
  animation-delay: 1s;
  animation-duration: 3s;
}
.star:nth-child(7){
    top: 300px;
    right: 0px;
    left: initial;
    animation-delay: 5.2s;
    animation-duration: 1.75s;
}
.star:nth-child(7)::before{
  animation-delay: 5.2s;
  animation-duration: 1.75s;
}
.star:nth-child(8){
    top: 0px;
    right: 700px;
    left: initial;
    animation-delay: 3.4s;
    animation-duration: 1.25s;
    box-shadow: 0 0 0 4px rgba(255, 243, 150, 0.1),0 0 0 8px rgba(255, 243, 150,0.1),0 0 20px rgba(255, 243, 150, 0.1);
}
.star:nth-child(8)::before{
  animation-delay: 3.4s;
  animation-duration: 1.25s;
  background: rgb(255, 243, 150);
  background: linear-gradient(90deg, rgb(255, 243, 150),transparent);
}
.star:nth-child(9){
    top: 0px;
    right: 1000px;
    left: initial;
    animation-delay: 0.75s;
    animation-duration: 2.25s;
}
.star:nth-child(9)::before{
  animation-delay: 0.75s;
  animation-duration: 2.25s;
}
.star:nth-child(10){
    top: 0px;
    right: 450px;
    left: initial;
    animation-delay: 8.75s;
    animation-duration: 2.75s;
    box-shadow: 0 0 0 4px rgba(255, 215, 150, 0.1),0 0 0 8px rgba(255, 215, 150,0.1),0 0 20px rgba(255, 215, 150, 0.1);
}
.star:nth-child(10)::before{
  animation-delay: .75s;
  animation-duration: 2.75s;
  background: rgb(255, 215, 150);
  background: linear-gradient(90deg, rgb(255, 215, 150),transparent);
}

@keyframes float {
  0% {
    background-position: top left;
  }
	10% { 
		background-position: bottom right;
	}
	20% { 
		background-position: top center;
	}
	30% { 
		background-position: center left;
	}
	40% {
    background-position: center bottom;
	}
  50% {
		background-position: bottom left;
	}
	60% { 
		background-position: center right;
	}
	70% { 
		background-position: center;
	}
	80% { 
		background-position: bottom left;
	}
	90% {
		background-position: top center; 
	}
	95% { 
		background-position: center;
	}
	100% { 
    background-position: top left;
	}
}

.cats-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.sammy {
  background: url('../../public/samwise.png');
  left: -20%;
  top: 120%;
  animation: sammy-slide-in linear 10s 600s forwards,
     hor-movement-sammy linear infinite 21s 610s alternate,
     ver-movement linear infinite 30s 610s alternate,
     rotate linear infinite 40s reverse 645s;
}

.gracie {
  background: url('../../public/gracie.png');
  left: 100%;
  top: 100%;
  animation: gracie-slide-in linear 10s 635s forwards,
     hor-movement-gracie linear infinite 25s 645s alternate,
     ver-movement linear infinite 22s 645s alternate,
     rotate linear infinite 20s normal 645s;
} 

.cat {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20%;
  width: 20%;
  position: absolute;
  z-index: 100;
}

@keyframes gracie-slide-in {
  0% {
    left: 100%;
    top: 100%;
  }
  100% {
    left: 80%;
    top: 80%;
  }
}

@keyframes sammy-slide-in {
  0% {
    left: -20%;
    top: 120%;
  }
  100% {
    left: 0;
    top: 80%;
  }
}

@keyframes hor-movement-sammy {
  from {
    left: 0;
  }
  to {
    left: 80%;
  }
}

@keyframes hor-movement-gracie {
  from {
    left: 80%;
  }
  to {
    left: 0;
  }
}

@keyframes ver-movement {
  0% {
    top: 80%;
  }
  100% {
    top: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {
    
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
